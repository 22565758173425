
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import roadMapApi from "@/core/services/RoadMap";
import XLSX from "xlsx";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter } from "vue-router";

export default defineComponent({
    name: "roadmap",
    components: {},
    data: function () {
        return {
            action: {
                id: 0,
                label: "",
                collaborators: "",
                deadline: "",
                category: 0,
                status: "TODO",
                commentary: "",
                impact: "",
            },
            roadmaps: [],
            categories: [],
        };
    },
    methods: {
        downloadRoadmap() {
            if (this.roadmaps && this.roadmaps.length > 0) {
                let suitableJson = this.roadmaps.map((action: any) => {
                    let status = "A faire";
                    if (action.status === "DONE") {
                        status = "Terminée";
                    } else if (action.status === "INPR") {
                        status = "En cours";
                    }
                    return {
                        Thématique: action.category.label,
                        Action: action.label,
                        Collaborateurs: action.collaborators,
                        Échéance: action.deadline,
                        Status: status,
                        Commentaires: action.commentary,
                    };
                });
                const data = XLSX.utils.json_to_sheet(suitableJson);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, data, "data");
                XLSX.writeFile(wb, "roadmaps.xlsx");
            }
        },
        deleteAction(action) {
            roadMapApi
                .deleteAction(action.pk)
                .then(() => {
                    location.reload();
                })
                .catch(() => {
                    this.displayErrorMsg("Une erreur est survenue lors de la suppression de l'action.");
                });
        },
        getRoadMaps() {
            roadMapApi
                .getRoadMaps()
                .then((response) => {
                    this.roadmaps = response.data["roadmaps"];
                })
                .catch(() => {
                    this.displayErrorMsg("Une erreur est survenue lors de la récupération des plans d'action.");
                });
        },
        displayErrorMsg(msg: string) {
            Swal.fire({
                text: msg,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Fermer",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                },
            });
        },
    },
    setup() {
        const router = useRouter();
        const { t } = useI18n();
        setCurrentPageBreadcrumbs(t("pages.roadmap.title"), []);

        function editRoadmap(this: any, action) {
            this.action.label = action.label;
            this.action.collaborators = action.collaborators;
            this.action.deadline = action.deadline;
            this.action.commentary = action.commentary;
            this.action.category = action.category.pk;
            this.action.status = action.status;
            this.action.id = action.pk;
            this.action.impact = action.impact;

            router.push({
                name: "add-roadmap",
                query: {
                    id: this.action.id,
                    label: this.action.label,
                    theme: this.action.category,
                    collaborators: this.action.collaborators,
                    category: this.action.category,
                    deadline: this.action.deadline,
                    impact: this.action.impact,
                    status: this.action.status,
                    commentary: this.action.commentary
                },
            });
        };
        return { t, editRoadmap };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.roadmap.title"), []);
        },
    },
    created() {
        this.getRoadMaps();
    },
});
