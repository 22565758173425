import ApiService from "@/core/services/ApiService";

const ADD_ACTION = "roadmap/add-action/";
const DELETE_ACTION = "roadmap/delete-action/<pk>/";
const ADD_CATEGORY = "roadmap/add-category/";
const UPDATE_CATEGORY = "roadmap/update-category/<pk>/";
const DELETE_CATEGORY = "roadmap/delete-category/";

const GET_ROADMAPS = "roadmap/get-roadmaps";
const GET_ALL_ROADMAPS = "roadmap/get-all-roadmaps/";
const GET_RELATED_ROADMAPS = "roadmap/get-related-roadmaps/";
const GET_ROADMAPCATEGORY = "roadmap/get-roadmap-category";
const UPDATE_ACTION = "roadmap/update-roadmap/<pk>/";
const GET_USER_ROADMAPS = "roadmap/user-roadmaps/<pk>";

export default {
    addAction(action) {
        ApiService.setHeader();
        return ApiService.post(ADD_ACTION, action);
    },
    deleteAction(action_id) {
        ApiService.setHeader();
        return ApiService.delete(DELETE_ACTION.replace("<pk>", action_id));
    },
    addCategory(action) {
        ApiService.setHeader();
        return ApiService.post(ADD_CATEGORY, action);
    },
    deleteCategory(category_id) {
        ApiService.setHeader();
        return ApiService.delete(DELETE_CATEGORY.replace("<pk>", category_id));
    },
    updateCategory(category_id, idea) {
        ApiService.setHeader();
        return ApiService.put(UPDATE_CATEGORY.replace("<pk>", category_id), idea);
    },
    updateAction(action_id, payload) {
        ApiService.setHeader();
        return ApiService.post(UPDATE_ACTION.replace("<pk>", action_id), payload);
    },
    getRoadMaps() {
        ApiService.setHeader();
        return ApiService.get(GET_ROADMAPS).then((response) => {
            return response.data;
        });
    },
    getRelatedRoadMaps(filters) {
        ApiService.setHeader();
        return ApiService.post(GET_RELATED_ROADMAPS, filters).then((response) => {
            return response.data;
        });
    },
    getAllRoadMaps(filters) {
        ApiService.setHeader();
        return ApiService.post(GET_ALL_ROADMAPS, filters).then((response) => {
            return response.data;
        });
    },
    getRoadMapCategories() {
        ApiService.setHeader();
        return ApiService.get(GET_ROADMAPCATEGORY).then((response) => {
            return response.data;
        });
    },
    getUserRoadmaps(user_id) {
        ApiService.setHeader();
        return ApiService.get(GET_USER_ROADMAPS.replace("<pk>", user_id)).then((response) => {
            return response.data;
        });
    }
};
